.intro {
  grid-area: intro;
  padding: 32px;
}

.text {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 30px;
  margin-top: 0;
}

.text_small {
  font-size: 16px;
  line-height: 1.8;
}

.faq {
  background-color: var(--color-bg-alt);
  grid-area: qrcodes;
  color: white;
  padding: 32px;
}

.faqTitle {
  font-size: clamp(24px, 6.8vw, 70px);
  font-weight: 600;
  line-height: 1;
  margin: 0;
  margin-bottom: 30px;
}

@media (min-width: 980px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "header header"
      "intro qrcodes";
    height: 100%;
  }

  .faq,
  .intro {
    padding: 38px;
  }

  .faqTitle {
    margin-bottom: 40px;
  }
}
